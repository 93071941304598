<template>
  <div class="text">
    <ion-text
      v-if="!acceptToSelect && !isComment"
      ref="textRef"
      v-long-press="openTextMenu"
      mode="md"
      :class="{ selectable: acceptToSelect }"
      @contextmenu.prevent="(ev) => openTextMenu(ev)"
      v-html="
        props.textData.length > 400 && !isExpanded
          ? textData.slice(0, textSize).trim() + '... '
          : textData
      "
    />
    <ion-text
      v-else
      ref="textRef"
      mode="md"
      :class="{ selectable: acceptToSelect }"
      @contextmenu="(ev) => openTextMenu(ev)"
      v-html="
        props.textData.length > 400 && !isExpanded
          ? textData.slice(0, textSize).trim() + '... '
          : textData
      "
    />
    <app-text-expand
      v-if="props.textData.length > 400 && !isExpanded && isLongText()"
      @on-show="showFull()"
    />
  </div>
</template>

<script lang="ts" setup>
import { Clipboard } from '@capacitor/clipboard';
import { IonText } from '@ionic/vue';
import { useSwiper } from 'swiper/vue';
import type { ComponentPublicInstance, ComputedRef, PropType } from 'vue';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { AppTextExpand } from '@/components';
import { LongPress } from '@/directives';
import { FeedTypeEnum, FeedFlagEnum, PostTextActionEnum } from '@/enums';
import {
  isNativeMobile,
  showToast,
  postTextMenu,
  htmlToText,
  isWebMobile,
  isAnyMobile,
  postTextMenuSheet,
  useLoading,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useTopicStore } from '@/store';

const props = defineProps({
  textData: {
    type: String,
    default: '',
  },
  feedType: {
    type: String as PropType<FeedTypeEnum>,
    default: () => '',
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    default: () => FeedFlagEnum.FeedPage,
  },
  isComment: {
    type: Boolean,
    default: () => false,
  },
});

const textRef = ref<ComponentPublicInstance | null>(null);
const router = useRouter();
const textSize = 400 as number;
const clipboard = Clipboard;
const vLongPress = LongPress;
const topicStore = useTopicStore();

const acceptToSelect: ComputedRef<boolean> = computed(() => {
  const isMobile = isWebMobile || isNativeMobile;

  if (isMobile) {
    return props.feedFlag === FeedFlagEnum.ConversationPage && !props.isComment;
  } else {
    return props.feedFlag === FeedFlagEnum.ConversationPage;
  }
});

const getLinks = async () => {
  if (textRef.value !== null) {
    const links = textRef.value.$el.querySelectorAll('a');
    links.forEach((element: HTMLLinkElement) => {
      let id = null as number | null;
      let label = null as string | null;
      let routeName = '';
      if (element.href.startsWith('platform://users')) {
        id = Number(element.href.split('platform://users/').join(''));
        routeName = ROUTES_NAME.USER_BY_ID;
      } else if (element.href.startsWith('platform://groups')) {
        id = Number(element.href.split('platform://groups/').join(''));
        routeName = ROUTES_NAME.GROUP_BY_ID;
      } else if (element.href.startsWith('platform://tags')) {
        label = element.href.split('platform://tags/').join('');
        //TODO: убрать когда тэги в постах будут с ID https://gitlab.united-grid.com/intra/intra-ionic/-/issues/593
        const topic = topicStore.getTopicByLabel(label);
        if (topic !== null) {
          id = topic.id;
        }
        routeName = ROUTES_NAME.TOPIC_BY_ID;
      } else {
        element.setAttribute('target', '_blank');
      }
      element.addEventListener('click', async function (event) {
        event.preventDefault();
        event.stopPropagation();

        /* TODO: убрать когда тэги в постах будут с ID */
        if (
          routeName === ROUTES_NAME.TOPIC_BY_ID &&
          id === null &&
          label !== null
        ) {
          await useLoading().create(t('loading'));
          const topic = await topicStore.topicByLabel(label);
          if (topic !== undefined) id = topic.id;
          await useLoading().dismiss();
        }
        /* --- */

        if (id !== null && routeName !== null) {
          await router.push({
            name: routeName,
            params: { id: id },
          });
        } else if (id === null && routeName !== null && routeName !== '') {
          return;
        } else {
          window.open(element.href, '_blank')?.focus();
        }
      });
    });
  }
};

const isLongText = () => {
  if (props.textData !== null) {
    return props.textData.length > textSize;
  }
};
const swiper = props.feedType === FeedTypeEnum.Announcement ? useSwiper() : '';
const isExpanded = ref<boolean>(
  props.feedFlag === FeedFlagEnum.ConversationPage ||
    props.feedFlag === FeedFlagEnum.ForceToReadModal
);
const showFull = async () => {
  isExpanded.value = true;
  if (props.feedType === FeedTypeEnum.Announcement && swiper !== '') {
    setTimeout(() => {
      swiper.value.update();
    }, 0);
  }

  setTimeout(async () => {
    await getLinks();
  }, 0);
};

onMounted(async () => {
  getLinks();
});

const { t } = useI18n();
const openTextMenu = async (ev: Event) => {
  ev.preventDefault();
  if (!acceptToSelect.value && !props.isComment) {
    ev.preventDefault();
    ev.stopPropagation();
    const result = isAnyMobile
      ? await postTextMenuSheet()
      : await postTextMenu(isNativeMobile ? undefined : ev);

    switch (result.data) {
      case PostTextActionEnum.CopyText: {
        const text = htmlToText(props.textData);
        if (isNativeMobile) {
          await clipboard.write({
            string: text,
          });
        } else {
          await navigator.clipboard.writeText(text);
        }

        await showToast(t('appPopoverMenu.copy.textCopied'), true);
        break;
      }

      default:
        break;
    }
  }
};
</script>
<style scoped lang="scss">
a {
  text-decoration: none;
}
a:hover {
  opacity: 0.7;
}
.expand {
  color: var(--ion-color-primary);
  white-space: nowrap;
}
.expand:hover {
  opacity: 0.7;
  cursor: pointer;
}
.text {
  overflow: hidden;
}
.text > ion-text {
  white-space: pre-line;
  text-align: justify;
  font-size: 0.9rem;
}
ion-text {
  user-select: auto;
}
ion-text:hover {
  cursor: text;
}
ion-text.selectable {
  user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}

@include respond-to-min-width(2xl) {
  .text > ion-text {
    font-size: 1rem;
  }
}
</style>
