<template>
  <div
    v-for="(error, index) in errorsArray"
    :key="`${name}_${index}`"
    class="block-error"
  >
    <ion-text color="danger" :class="error.class">
      <template v-if="errorsMessageFlag">
        {{ error.message }}
      </template>
      <template v-else>
        {{ error }}
      </template>
    </ion-text>
  </div>
</template>

<script lang="ts" setup>
import { IonText } from '@ionic/vue';
import type { PropType } from 'vue';

defineProps({
  name: {
    type: String,
    required: true,
  },
  errorsArray: {
    type: Array as PropType<any>,
    require: false,
    default: () => [],
  },
  errorsMessageFlag: {
    type: Boolean,
    require: false,
  },
});
</script>

<style scoped lang="scss">
.block-error {
  margin-top: 0.5rem;
  padding-left: 1rem;
  font-size: 0.8rem;
}
.block-error ion-text.success {
  color: #4caf50;
}
</style>
