<template>
  <template
    v-if="
      postType === PostTypeActionEnum.Comment ||
      postType === PostTypeActionEnum.TaskComment
    "
  >
    <div>
      <div v-if="isSendLoading" class="loader">
        <icons-provider
          :icon-props="{ width: '22', height: '22' }"
          name="circleAnim"
        />
      </div>

      <div class="files-list">
        <template v-if="files.length > 0">
          <close-block-wrapper
            v-for="item in files"
            :key="item.key"
            class="file-wrapper"
            @onAction="onDeleteFile(item)"
          >
            <file-icon-preview :payload="item.payload" />
          </close-block-wrapper>
        </template>

        <template v-if="attachedFiles.length > 0">
          <close-block-wrapper
            v-for="item in attachedFiles"
            :key="item.key"
            class="file-wrapper"
            @onAction="onDeleteFile(item)"
          >
            <media-image
              v-if="!!item.image"
              :name="item.name"
              :internal="item.key"
              :image="item.image"
            />

            <media-image
              v-else-if="item.videoPreview !== null"
              :name="item.name"
              :internal="item.key"
              :image="item.videoPreview"
            />
            <file-info v-else :size="item.size" :name="item.name" />
          </close-block-wrapper>
        </template>

        <template v-if="tempFiles.length > 0">
          <close-block-wrapper
            v-for="item in tempFiles"
            :key="item.key"
            class="file-wrapper"
            @onAction="onDeleteFile(item)"
          >
            <media-image
              v-if="!!item.image"
              :name="item.name"
              :internal="item.key"
              :image="item.image"
            />

            <media-image
              v-else-if="item.videoPreview !== null"
              :name="item.name"
              :internal="item.key"
              :image="item.videoPreview"
            />
            <file-info
              v-else
              :type="item.type || item.contentType"
              :size="item.size"
              :name="item.name"
            />
          </close-block-wrapper>
        </template>

        <template v-if="attachedWikis.length > 0">
          <close-block-wrapper
            v-for="item in attachedWikis"
            :key="item.id"
            class="file-wrapper"
            @onAction="onDeleteWiki(item)"
          >
            <file-info :name="item.name" type="wiki" />
          </close-block-wrapper>
        </template>
      </div>

      <ion-toolbar
        mode="md"
        :class="[
          { 'task-toolbar': postType === PostTypeActionEnum.TaskComment },
        ]"
        @click.stop
      >
        <div class="toolbar-container">
          <ion-item
            class="attach-btn"
            :disabled="isFilesLoading"
            type="button"
            lines="none"
            @click="attachAction($event)"
          >
            <ion-icon size="large" :icon="icons.attach" />
          </ion-item>
          <ion-textarea
            ref="textAreaRef"
            v-model="text"
            mode="ios"
            :rows="1"
            :auto-grow="true"
            :placeholder="$t('feed.comments.text')"
            @keydown.enter="keyUp"
            @ionFocus="commentFocus()"
            @ionBlur="commentBlur()"
            @ionInput="onChange"
          />

          <ion-buttons mode="md">
            <feed-send-button
              :is-loading="isSendLoading"
              :disabled="isFilesLoading || nothingToPush"
              :post-type="postType"
              :is-small="postType === PostTypeActionEnum.TaskComment"
              @onSendClick="actionSend"
            />
          </ion-buttons>
        </div>
      </ion-toolbar>

      <user-choose-list
        v-if="
          activePost === postId && postType !== PostTypeActionEnum.TaskComment
        "
        :message="text"
        :caret="caret"
        @chooseUser="onAppend"
      />

      <feed-topic-choose-list
        :message="text"
        :caret="caret"
        @chooseTopic="onAppend"
      />
    </div>
  </template>

  <template
    v-if="
      postType === PostTypeActionEnum.Text ||
      postType === PostTypeActionEnum.Announcement ||
      postType === PostTypeActionEnum.Poll ||
      postType === PostTypeActionEnum.Idea ||
      postType === PostTypeActionEnum.Badge ||
      postType === PostTypeActionEnum.Task
    "
  >
    <feed-post-photo
      v-if="postType === PostTypeActionEnum.Announcement"
      :announcement-current-photo="coverImage"
      :cover-delete-flag="coverDeleteFlag"
      @onPhotoSelected="getSelectedPhotoId"
      @onPhotoUploaded="getSelectedPhotoObj"
      @onPhotoLoading="photoLoading"
    />

    <div class="files-list">
      <template v-if="files.length > 0">
        <close-block-wrapper
          v-for="item in files"
          :key="item.key"
          class="file-wrapper"
          @onAction="onDeleteFile(item)"
        >
          <file-icon-preview :payload="item.payload as string" />
        </close-block-wrapper>
      </template>

      <template v-if="attachedFiles.length > 0">
        <close-block-wrapper
          v-for="item in attachedFiles"
          :key="item.key"
          class="file-wrapper"
          @onAction="onDeleteFile(item)"
        >
          <media-image
            v-if="!!item.image"
            :name="item.name"
            :internal="item.key"
            :image="item.image"
          />

          <media-image
            v-else-if="item.videoPreview !== null"
            :name="item.name"
            :internal="item.key"
            :image="item.videoPreview"
          />
          <file-info v-else :size="item.size" :name="item.name" />
        </close-block-wrapper>
      </template>

      <template v-if="tempFiles.length > 0">
        <close-block-wrapper
          v-for="item in tempFiles"
          :key="item.key"
          class="file-wrapper"
          @onAction="onDeleteFile(item)"
        >
          <media-image
            v-if="!!item.image"
            :name="item.name"
            :internal="item.key"
            :image="item.image"
          />

          <media-image
            v-else-if="!!item.videoPreview"
            :name="item.name"
            :internal="item.key"
            :image="item.videoPreview"
          />
          <file-info
            v-else
            :type="item.type || item.contentType"
            :size="item.size"
            :name="item.name"
          />
        </close-block-wrapper>
      </template>

      <template v-if="attachedWikis.length > 0">
        <close-block-wrapper
          v-for="item in attachedWikis"
          :key="item.id"
          class="file-wrapper"
          @onAction="onDeleteWiki(item)"
        >
          <file-info :name="item.name" type="wiki" />
        </close-block-wrapper>
      </template>
    </div>

    <div class="post_create-controls">
      <div v-if="postType !== PostTypeActionEnum.Badge" class="attach">
        <ion-button @click="attachAction($event)">
          <ion-icon slot="icon-only" :icon="icons.attach" />
        </ion-button>
      </div>
      <div class="send">
        <feed-on-behalf-selector
          v-if="
            isPostOnBehalfAvailable &&
            (postType === PostTypeActionEnum.Text ||
              postType === PostTypeActionEnum.Announcement ||
              postType === PostTypeActionEnum.Poll ||
              postType === PostTypeActionEnum.Idea)
          "
          class="behalf-select-button"
          :placeholder="t('feed.onBehalf')"
          @onChange="selectOnBehalfUser"
        />
        <app-group-select-button
          v-if="postType !== PostTypeActionEnum.Badge"
          class="group-select-button"
          :post-type="postType"
          :is-unbound="false"
          :note="$t('files.publisherNote')"
          @onSelectChange="selectChange"
        />

        <task-management-project-switch-button
          v-if="postType === PostTypeActionEnum.Task"
          :with-routing="false"
          is-post-creation
          :selected-group-id="selectedGroupToPost?.id"
        />

        <app-schedule-post-button
          v-if="
            postType === PostTypeActionEnum.Text ||
            postType === PostTypeActionEnum.Announcement ||
            postType === PostTypeActionEnum.Poll ||
            postType === PostTypeActionEnum.Idea ||
            showScheduleButton
          "
          class="schedule-post-button"
          :post-type="postType"
          @onSelectDate="selectDate"
        />

        <div
          v-if="
            postType === PostTypeActionEnum.Task ? !!taskData?.projectId : true
          "
          class="preview-post-button"
        >
          <ion-button fill="outline" @click="actionPreview">
            <span class="wrap-text">{{ t('preview') }}</span>
          </ion-button>
        </div>

        <div class="submit">
          <feed-send-button
            :is-loading="isSendLoading"
            :disabled="disabledToSend"
            :post-type="postType"
            @onSendClick="actionSend"
          />
        </div>
      </div>

      <!-- NOTE: Badge post type doesn't support upload files -->
      <!-- NOTE: On mobile devices, the drag zone is not displayed -->
      <!-- NOTE: The drag zone is displayed when the drag is active or the drop is loading -->
      <app-drag-zone
        v-if="
          postType !== PostTypeActionEnum.Badge &&
          !isAnyMobile &&
          (dragIsActive || dropLoading)
        "
        class="drag"
        :file-types="UploadFileTypes.ManyDifferentFiles"
        is-publisher
        @files="dropedFiles"
        @allFilesIsLoaded="dropIsLoading"
      />
    </div>
  </template>

  <template
    v-if="
      postType === PostTypeActionEnum.Event ||
      postType === PostTypeActionEnum.CalendarEvent
    "
  >
    <div class="post_create-controls">
      <div />
      <div class="send">
        <feed-on-behalf-selector
          v-if="isPostOnBehalfAvailable"
          :placeholder="t('feed.onBehalf')"
          @onChange="selectOnBehalfUser"
        />
        <app-group-select-button
          class="group-select-button"
          :post-type="postType"
          :is-unbound="false"
          :note="$t('files.publisherNote')"
          @onSelectChange="selectChange"
        />
        <app-schedule-post-button
          v-if="showScheduleButton"
          class="schedule-post-button"
          :post-type="postType"
          @onSelectDate="selectDate"
        />
        <div class="preview-post-button">
          <ion-button fill="outline" @click="actionPreview">
            <span class="wrap-text">{{ t('preview') }}</span>
          </ion-button>
        </div>
        <div class="submit">
          <feed-send-button
            :is-loading="isSendLoading"
            :disabled="disabledToSend"
            :post-type="postType"
            @onSendClick="actionSend"
          />
        </div>
      </div>
    </div>
  </template>

  <template
    v-if="
      postType === PostTypeActionEnum.EditText ||
      postType === PostTypeActionEnum.EditEvent ||
      postType === PostTypeActionEnum.EditIdea ||
      postType === PostTypeActionEnum.EditPoll ||
      postType === PostTypeActionEnum.EditAnnouncement
    "
  >
    <feed-post-photo
      v-if="postType === PostTypeActionEnum.EditAnnouncement"
      :announcement-current-photo="coverImage"
      :cover-delete-flag="coverDeleteFlag"
      @onPhotoSelected="getSelectedPhotoId"
      @onPhotoLoading="photoLoading"
    />
    <div v-if="postType !== PostTypeActionEnum.EditEvent" class="files-list">
      <template v-if="files.length > 0">
        <close-block-wrapper
          v-for="item in files"
          :key="item.key"
          class="file-wrapper"
          @onAction="onDeleteFile(item)"
        >
          <file-icon-preview :payload="item.payload as string" />
        </close-block-wrapper>
      </template>

      <template v-if="attachedFiles.length > 0">
        <close-block-wrapper
          v-for="item in attachedFiles"
          :key="item.key"
          class="file-wrapper"
          @onAction="onDeleteFile(item)"
        >
          <!-- TODO add :lqip="item.image?.lqip" -->
          <media-image
            v-if="!!item.image"
            :name="item.name"
            :internal="item.key"
            :image="item.image"
          />
          <!-- TODO add :lqip="item.image?.lqip" -->
          <media-image
            v-else-if="item.videoPreview !== null"
            :name="item.name"
            :internal="item.key"
            :image="item.videoPreview"
          />
          <file-info v-else :size="item.size" :name="item.name" />
        </close-block-wrapper>
      </template>

      <template v-if="tempFiles.length > 0">
        <close-block-wrapper
          v-for="item in tempFiles"
          :key="item.key"
          class="file-wrapper"
          @onAction="onDeleteFile(item)"
        >
          <!-- TODO add :lqip="item.image?.lqip" -->
          <media-image
            v-if="!!item.image"
            :name="item.name"
            :internal="item.key"
            :image="item.image"
          />
          <!-- TODO add :lqip="item.image?.lqip" -->
          <media-image
            v-else-if="!!item.videoPreview"
            :name="item.name"
            :internal="item.key"
            :image="item.videoPreview"
          />
          <file-info
            v-else
            :type="item.type || item.contentType"
            :size="item.size"
            :name="item.name"
          />
        </close-block-wrapper>
      </template>

      <template v-if="attachedWikis.length > 0">
        <close-block-wrapper
          v-for="item in attachedWikis"
          :key="item.id"
          class="file-wrapper"
          @onAction="onDeleteWiki(item)"
        >
          <file-info :name="item.name" type="wiki" />
        </close-block-wrapper>
      </template>
    </div>

    <div class="post_create-controls">
      <div v-if="postType !== PostTypeActionEnum.EditEvent" class="attach">
        <ion-button @click="attachAction($event)">
          <ion-icon slot="icon-only" :icon="icons.attach" />
        </ion-button>
      </div>
      <ion-button
        v-if="postType === PostTypeActionEnum.EditAnnouncement"
        class="delete"
        mode="md"
        fill="clear"
        :disabled="!coverImage"
        @click="deleteCoverImage"
      >
        {{ t('coverImage.coverMenu.delete') }}
      </ion-button>
      <div class="send">
        <feed-on-behalf-selector
          v-if="isPostOnBehalfAvailable"
          :placeholder="t('feed.onBehalf')"
          :author="postData.author"
          @onChange="selectOnBehalfUser"
        />
        <div class="submit">
          <feed-send-button
            :is-loading="isSendLoading"
            :disabled="disabledToSend"
            :post-type="postType"
            @onSendClick="actionSend"
          />
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts" setup>
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonTextarea,
  IonToolbar,
} from '@ionic/vue';
import { useEventBus } from '@vueuse/core';
import {
  alertCircleOutline,
  attachOutline,
  documentOutline,
  imageOutline,
  imagesOutline,
  timerOutline,
  trashOutline,
} from 'ionicons/icons';
import { cloneDeep, debounce, filter } from 'lodash';
import type { PropType, ComputedRef, ComponentPublicInstance } from 'vue';
import { ref, watch, computed, onUnmounted, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import {
  AppGroupSelectButton,
  CloseBlockWrapper,
  FeedPostPhoto,
  FileInfo,
  FileIconPreview,
  MediaImage,
  UserChooseList,
  FeedTopicChooseList,
  FeedSendButton,
  TaskManagementProjectSwitchButton,
  IconsProvider,
  AppSchedulePostButton,
  FeedOnBehalfSelector,
  AppDragZone,
} from '@/components';
import {
  PostTypeActionEnum,
  PostUploadFileEnum,
  DocumentTypeEnum,
  UserRoleEnum,
  UsersFilterEnum,
  FeedTypeEnum,
  IdeaStatusEnum,
  FeedEventAnswerEnum,
  UploadFileTypes,
  EventBusEnum,
} from '@/enums';
import {
  componentDocsAttachment,
  componentDocsCreateFile,
  componentPostPreview,
  componentWikiCreate,
  DateHelper,
  filesHybrid,
  isAnyMobile,
  keyUpSend,
  postUploadFileMenu,
  postUploadFileMenuSheet,
  showToast,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { defaultPost } from '@/models';
import { ROUTES_NAME } from '@/router';
import {
  usePostStore,
  useEventStore,
  useNetworkStore,
  useUserStore,
  useProjectsStore,
  useWikiStore,
} from '@/store';
import type {
  CommentsDataModel,
  DocEntity,
  FileModel,
  GroupEntity,
  RequestAnnouncementCreateModel,
  RequestAnnouncementEditModel,
  RequestBadgeCreateModel,
  RequestBadgeFullCreateModel,
  TaskManagementCreateTaskRequestModel,
  RequestCommentCreateModel,
  RequestEventCreateModel,
  RequestIdeaCreateModel,
  RequestIdeaEditModel,
  RequestPollCreateModel,
  RequestPollEditModel,
  RequestPostEditModel,
  RequestStandardPostCreateModel,
  WikiModel,
  RequestTaskCommentCreateModel,
  PostEntity,
  MediaModel,
  UserShortModel,
  UserCurrentModel,
  NetworkFullSettingsModel,
} from '@/types';

// Props
const props = defineProps({
  postId: {
    type: Number,
    default: () => 0,
  },
  postType: {
    type: String as PropType<PostTypeActionEnum>,
    required: true,
  },
  sendFlag: {
    type: Boolean,
    default: () => false,
  },
  postData: {
    type: Object as PropType<PostEntity>,
    default: () => null,
  },
  ideaData: {
    type: Object as PropType<RequestIdeaCreateModel>,
    default: () => null,
  },
  badgeData: {
    type: Object as PropType<RequestBadgeFullCreateModel>,
    default: () => null,
  },
  updateData: {
    type: Object as PropType<RequestStandardPostCreateModel>,
    default: () => null,
  },
  eventData: {
    type: Object as PropType<RequestEventCreateModel>,
    default: () => null,
  },
  announcementData: {
    type: Object as PropType<RequestAnnouncementCreateModel>,
    default: () => null,
  },
  pollData: {
    type: Object as PropType<RequestPollCreateModel>,
    default: () => null,
  },
  taskData: {
    type: Object as PropType<TaskManagementCreateTaskRequestModel>,
    default: () => null,
  },
  taskId: {
    type: Number,
    default: () => 0,
  },
  keyUpAction: {
    type: Boolean,
    default: () => false,
  },
  announcementCurrentPhoto: {
    type: String || null,
    default: () => null,
  },
});

// Store
const postStore = usePostStore();
const eventStore = useEventStore();
const networkStore = useNetworkStore();
const userStore = useUserStore();
const projectsStore = useProjectsStore();

// Other
const icons = {
  attach: attachOutline,
  alert: alertCircleOutline,
  image: imageOutline,
  images: imagesOutline,
  document: documentOutline,
  trash: trashOutline,
  timer: timerOutline,
};
const { t } = useI18n();
const route = useRoute();

// Variables
const caret = ref(0);
const selectedGroupToPost = ref<GroupEntity | undefined>(undefined);
const coverDeleteFlag = ref<boolean>(false);

const postPhotoObj = ref<MediaModel | null>(null);
const postPhoto = ref<string>('');
const text = ref<string>('');
const coverImage = ref<string>(props.announcementCurrentPhoto);

const isPhotoLoading = ref<boolean>(false);
const isSendLoading = ref<boolean>(false);
const isAttachmentChanged = ref<boolean>(false);
const isOnBehalfUserChanged = ref<boolean>(false);

const textAreaRef = ref<ComponentPublicInstance | null>(null);
const textAreaEl = ref<HTMLTextAreaElement | null>(null);

const files = ref<FileModel[]>([]);
const tempFiles = ref<FileModel[]>([]);
const tempOriginFiles = ref<FileModel[]>([]);
const attachedFiles = ref<FileModel[]>([]);
const attachedOriginFiles = ref<FileModel[]>([]);
const attachedWikis = ref<WikiModel[]>([]);
const attachedOriginWikis = ref<WikiModel[]>([]);
const selectedDate = ref<string>('');
const onBehalfUser = ref<UserShortModel | undefined>(undefined);
const dragIsActive = ref<boolean>(false);

//NOTE: #1682 OnlyOffice attachment content loss - fast fix
const onlyOfficeFileHere = ref<boolean>(false);

const routeName: ComputedRef<string> = computed(
  () => (route.name as string) || ''
);

const showScheduleButton: ComputedRef<boolean> = computed(
  () => routeName.value !== ROUTES_NAME.GROUP_BY_ID
);

const isFilesLoading: ComputedRef<boolean> = computed(
  () => filesHybrid.isLoading.value
);
const draftTempFiles: ComputedRef<FileModel[]> = computed(
  () => postStore.draftPosts.tempFiles
);
const draftExistFiles: ComputedRef<FileModel[]> = computed(
  () => postStore.draftPosts.existFiles
);
const draftWikis: ComputedRef<WikiModel[]> = computed(
  () => postStore.draftPosts.wikis
);
const uploads: ComputedRef<FileModel[]> = computed(() =>
  files.value.filter((f: FileModel) => f.key !== '')
);

const currentUser: ComputedRef<UserCurrentModel | null> = computed(
  () => userStore.current
);

const currentUserRoleId: ComputedRef<number> = computed(
  () => userStore.current?.roleId ?? 0
);

const currentNetworkSettings: ComputedRef<NetworkFullSettingsModel | null> =
  computed(() => networkStore.getCurrentSettings);

const isPostOnBehalfAvailable: ComputedRef<boolean | undefined> = computed(
  () =>
    currentNetworkSettings.value?.allowPostOnBehalf &&
    currentUserRoleId.value >= UserRoleEnum.Moderator
);

const networkAllowPostToFeed: ComputedRef<boolean> = computed(
  () => networkStore.settings?.allowPostToFeed ?? false
);
const activePost: ComputedRef<number | null> = computed(
  () => postStore.activePost
);

const nothingToPush = computed(
  () =>
    !attachedFiles.value.length &&
    !attachedWikis.value.length &&
    !tempFiles.value.length &&
    !files.value.length &&
    text.value.trim().length === 0
);

const checkAttachments: ComputedRef<boolean> = computed(() => {
  switch (props.postType) {
    case PostTypeActionEnum.EditBadge:
    case PostTypeActionEnum.EditText:
    case PostTypeActionEnum.EditIdea:
    case PostTypeActionEnum.EditPoll:
    case PostTypeActionEnum.EditEvent:
      return true;

    default:
      return false;
  }
});

const disabledToSend: ComputedRef<boolean> = computed(() => {
  // sendFlag - valid text => false / invalid text => true
  const { postType, sendFlag } = props;
  // Можно постить в ленту если роль равна или больше 30
  const userIsHaveRights =
    currentUser.value?.roleId && currentUser.value?.roleId >= UserRoleEnum.User;

  const allowToPost = selectedGroupToPost.value
    ? true
    : userIsHaveRights && networkAllowPostToFeed.value;

  const validTextIsPresent: boolean =
    (props.updateData &&
      props.updateData.text.trim().length > 0 &&
      !sendFlag) ||
    (!props.updateData && !sendFlag);

  const somethingIsLoading: boolean =
    isFilesLoading.value || isPhotoLoading.value || isSendLoading.value;

  const somethingIsAttached: boolean =
    attachedFiles.value.length > 0 ||
    attachedWikis.value.length > 0 ||
    tempFiles.value.length > 0;

  const standardCase =
    allowToPost &&
    !somethingIsLoading &&
    (validTextIsPresent || somethingIsAttached || uploads.value.length > 0);

  const editCase =
    !somethingIsLoading &&
    (validTextIsPresent ||
      isAttachmentChanged.value ||
      isOnBehalfUserChanged.value);

  const editAnnouncementCase =
    !somethingIsLoading &&
    (!sendFlag ||
      isAttachmentChanged.value ||
      coverDeleteFlag.value ||
      postPhoto.value.length > 0 ||
      isOnBehalfUserChanged);

  switch (postType) {
    case PostTypeActionEnum.Text:
      return !standardCase;

    case PostTypeActionEnum.EditAnnouncement:
      return !editAnnouncementCase;

    case PostTypeActionEnum.EditBadge:
    case PostTypeActionEnum.EditText:
    case PostTypeActionEnum.EditIdea:
    case PostTypeActionEnum.EditPoll:
    case PostTypeActionEnum.EditEvent:
      return !editCase;

    default:
      return sendFlag || somethingIsLoading || !allowToPost;
  }
});

// Actions
const getSelectedPhotoId = (id: string) => {
  postPhoto.value = id;
  coverImage.value = id;
  coverDeleteFlag.value = false;
};

const getSelectedPhotoObj = (image: MediaModel) => {
  postPhotoObj.value = image;
};

const photoLoading = (loading: boolean) => {
  isPhotoLoading.value = loading;
};
const deleteCoverImage = () => {
  postPhoto.value = '';
  coverImage.value = '';
  coverDeleteFlag.value = true;
};

const processDate = (date: string) => {
  const partsOfDate = date.split(/[.: ]/);
  const result = new Date(
    +partsOfDate[2],
    +partsOfDate[1] - 1,
    +partsOfDate[0],
    +partsOfDate[3],
    +partsOfDate[4]
  );
  const offset = result.getTimezoneOffset();
  result.setMinutes(result.getMinutes() - offset);
  return result;
};

const actionPreview = async () => {
  const author = {
    id: onBehalfUser.value?.id || currentUser.value?.id,
    fullName: onBehalfUser.value?.fullName || currentUser.value?.fullName,
    mainAlias: onBehalfUser.value?.mainAlias || currentUser.value?.mainAlias,
    isActive: onBehalfUser.value?.isActive || currentUser.value?.isActive,
    avatar: onBehalfUser.value?.image || currentUser.value?.avatar || null,
  };
  const initiator = {
    id: currentUser.value?.id,
    fullName: currentUser.value?.fullName,
    mainAlias: currentUser.value?.mainAlias,
    isActive: currentUser.value?.isActive,
    avatar: currentUser.value?.avatar || null,
  };
  const updateData = {
    author: author,
    initiator: initiator,
    bodyHtml: props.updateData?.text.trim() || text.value.trim(),
    attachedFiles: {
      count: attachedFiles.value.length,
      data: attachedFiles.value,
    },
    attachedWikis: {
      count: attachedWikis.value.length,
      data: attachedWikis.value,
    },
    group: selectedGroupToPost.value,
    plannedPostData: {
      plannedDate: selectedDate.value,
      processStatus: 1,
      tempFiles: [...uploads.value, ...tempFiles.value],
    },
    usersReadCount: 1,
  };
  const data = Object.assign(cloneDeep(defaultPost), updateData);
  data.createdAt = DateHelper.getIsoNow();
  switch (props.postType) {
    case PostTypeActionEnum.Text:
      data.messageType = FeedTypeEnum.Text;
      data.ccUsers = (props.updateData.users as UserShortModel[]) || [];
      break;
    case PostTypeActionEnum.Event:
      data.messageType = FeedTypeEnum.Event;
      data.ccUsers = (props.eventData.users as UserShortModel[]) || [];
      data.eventData = {
        title: props.eventData.title.trim(),
        location: props.eventData.place.trim(),
        //TODO: return for #1548 datetime: props.eventData.date,
        datetime: processDate(props.eventData.date).toISOString(),
        durationMinutes: props.eventData.duration,
        answer: FeedEventAnswerEnum.None,
        attendingsCount: 0,
        videoChatLink: null,
        isBlocker: props.eventData.isBlocker,
        blockerSubscribers: 0,
        currentUserSubscribed: false,
      };
      break;
    case PostTypeActionEnum.Announcement:
      data.title = props.announcementData.title.trim();
      data.bodyHtml = props.announcementData.text.trim();
      data.image = postPhotoObj.value;
      data.messageType = FeedTypeEnum.Announcement;
      break;
    case PostTypeActionEnum.Poll:
      {
        let dateObj = null;
        if (props.pollData.limitDate) {
          dateObj = processDate(props.pollData.limitDate);
        }
        data.messageType = FeedTypeEnum.Poll;
        data.pollData = {
          mainHtml: props.pollData.text.trim(),
          allowAddOptions: props.pollData.allowUserAddOptions,
          allowSeveralAnswers: props.pollData.allowMultipleAnswers,
          anonymousMode: props.pollData.isAnonymousVoting,
          votes: 0,
          voters: 0,
          isAnswered: true,
          timeLimit: dateObj ? dateObj.toISOString() : '',
          options: props.pollData.questionOptions.map((item) => {
            return {
              text: item,
              isAnswered: false,
              votesCount: 0,
              voteIds: [],
            };
          }),
        };
      }
      break;
    case PostTypeActionEnum.Idea:
      data.messageType = FeedTypeEnum.Idea;
      data.ideaData = {
        title: props.ideaData.title.trim(),
        problem: props.ideaData.problem.trim(),
        solution: props.ideaData.solution.trim(),
        results: props.ideaData.results.trim(),
        status: IdeaStatusEnum.New,
        statusText: 'New',
      };
      data.likes = {
        count: 1,
        data: [],
        isLiked: true,
      };
      break;
    case PostTypeActionEnum.Task:
      data.taskData = {
        id: 0,
        identifier: 0,
        assignee: (props.taskData?.assignee as UserShortModel) || null,
        author: author as unknown as UserShortModel,
        comments: { count: 0, data: [] },
        dateArchived: null,
        dateClosed: null,
        dateCreated: new Date().toISOString(),
        dateDue: props.taskData.dateDue,
        dateLastUpdated: null,
        description: props.taskData.description,
        files: {
          count: attachedFiles.value.length,
          data: attachedFiles.value,
        },
        history: null,
        isArchived: false,
        isClosed: false,
        links: { count: 0, data: null },
        milestone: props.taskData?.milestone || null,
        modifier: null,
        participants: {
          count: 1,
          data: props.taskData.assignee
            ? [props.taskData.assignee as UserShortModel]
            : [],
        },
        projectId: props.taskData.projectId || 0,
        tags: {
          count: props.taskData?.tags?.length || 0,
          data: props.taskData?.tags || [],
        },
        title: props.taskData.title.trim(),
        wikis: {
          count: attachedWikis.value.length,
          data: attachedWikis.value,
        },
        type: 'Plug',
        notify: null,
      };
      data.messageType = FeedTypeEnum.Task;
      break;
    case PostTypeActionEnum.Badge:
      data.messageType = FeedTypeEnum.Badge;
      data.attachedBadge = {
        id: props.badgeData.badgeId as number,
        assignedTo: props.badgeData.users as UserShortModel[],
      };
      break;
  }
  const previewResult = await componentPostPreview(data);
  if (previewResult.data) {
    await actionSend();
  }
};

const actionSend = async () => {
  if (
    (props.postType === PostTypeActionEnum.Comment ||
      props.postType === PostTypeActionEnum.TaskComment) &&
    (isFilesLoading.value || nothingToPush.value)
  ) {
    return;
  }
  if (
    props.postType !== PostTypeActionEnum.Comment &&
    props.postType !== PostTypeActionEnum.TaskComment &&
    disabledToSend.value
  ) {
    return;
  }

  isSendLoading.value = true;

  //NOTE: #1682 OnlyOffice attachment content loss - fast fix
  if (onlyOfficeFileHere.value) {
    console.log('Awaiting for onlyOffice file creation...');
    await new Promise((resolve) => setTimeout(resolve, 15000));
  }

  const data = {
    text: text.value.trim(),
    files: [...uploads.value, ...tempFiles.value],
    attachedFiles: attachedFiles.value,
    attachedWikis: attachedWikis.value,
    postPhotoId: postPhoto.value,
    groupToPost: selectedGroupToPost.value,
  };
  text.value = ' ';
  files.value = [];
  tempFiles.value = [];
  attachedFiles.value = [];
  attachedWikis.value = [];
  postPhoto.value = '';

  switch (props.postType) {
    // Comment
    case PostTypeActionEnum.Comment:
      {
        const comment = {
          text: data.text,
          messageId: props.postId,
          fileTempIds: data.files.map((f: FileModel) => f.key),
          fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
          wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
        } as RequestCommentCreateModel;
        if (!(await postStore.commentCreate(comment))) {
          await showToast(t('feed.comments.commentNotSend'), false);
        }
      }
      break;
    // Comment in task
    case PostTypeActionEnum.TaskComment:
      {
        const comment = {
          text: data.text,
          taskId: props.taskId,
          fileTempIds: data.files.map((f: FileModel) => f.key),
          fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
          wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
        } as RequestTaskCommentCreateModel;
        if (!(await projectsStore.taskCommentCreate(comment))) {
          await showToast(t('feed.comments.commentNotSend'), false);
        } else {
          emit('onSendSuccessful');
        }
      }
      break;
    // Standard post
    case PostTypeActionEnum.Text:
      {
        const postData = {
          // Task #414
          text:
            props.updateData.text.length === 0
              ? ' '
              : props.updateData.text.trim(),
          userIds: props.updateData.userIds,
          groupIds: props.updateData.groupIds,
          userEmails: props.updateData.userEmails,
          toGroupId: data.groupToPost?.id,
          fileTempIds: data.files.map((f: FileModel) => f.key),
          fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
          wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
        } as RequestStandardPostCreateModel;
        if (selectedDate.value) {
          postData.plannedDate = selectedDate.value;
        }
        if (onBehalfUser.value) {
          postData.onBehalfUserId = onBehalfUser.value.id;
        }

        const sendPost = async (postData: RequestStandardPostCreateModel) => {
          const result = selectedDate.value
            ? await postStore.postPlannedCreate(postData)
            : await postStore.postCreate(postData);

          if (result) {
            emit('onSendSuccessful');
            postStore.$patch({
              draftPosts: {
                standardPost: null,
              },
            });
            removeAllFiles();
          } else {
            await showToast(t('feed.postNotSend'), false);
          }
        };
        await sendPost(postData);
      }
      break;
    //Idea
    case PostTypeActionEnum.Idea:
      {
        const idea = {
          title: props.ideaData.title.trim(),
          problem: props.ideaData.problem.trim(),
          solution: props.ideaData.solution.trim(),
          results: props.ideaData.results.trim(),
          toGroupID: data.groupToPost?.id,
          fileTempIds: data.files.map((f: FileModel) => f.key),
          fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
          wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
          fileIds: [],
        } as RequestIdeaCreateModel;

        if (selectedDate.value) {
          idea.plannedDate = selectedDate.value;
        }
        if (onBehalfUser.value) {
          idea.onBehalfUserId = onBehalfUser.value.id;
        }

        const sendPost = async (idea: RequestIdeaCreateModel) => {
          const result = selectedDate.value
            ? await postStore.ideaPlannedCreate(idea)
            : await postStore.ideaCreate(idea);

          if (result) {
            emit('onSendSuccessful');
            postStore.$patch({
              draftPosts: {
                idea: null,
              },
            });
            removeAllFiles();
          } else {
            await showToast(t('feed.postNotSend'), false);
          }
        };
        await sendPost(idea);
      }
      break;

    //Badge
    case PostTypeActionEnum.Badge:
      {
        const badge = {
          text: props.badgeData.text.trim(),
          badgeId: props.badgeData.badgeId,
          userIds: props.badgeData.userIds,
        } as RequestBadgeCreateModel;
        if (!(await postStore.badgeCreate(badge))) {
          await showToast(t('feed.postNotSend'), false);
        } else {
          emit('onSendSuccessful');
          postStore.$patch({
            draftPosts: {
              badge: null,
            },
          });
        }
      }
      break;

    //Event
    case PostTypeActionEnum.Event:
    case PostTypeActionEnum.CalendarEvent:
      {
        const event = {
          title: props.eventData.title.trim(),
          text: props.eventData.text.trim(),
          date: props.eventData.date,
          duration: props.eventData.duration,
          place: props.eventData.place.trim(),
          userIds: props.eventData.userIds,
          groupIds: props.eventData.groupIds,
          userEmails: props.eventData.userEmails,
          toGroupID: data.groupToPost?.id,
          endDate: props.eventData.endDate,
          isBlocker: props.eventData.isBlocker,
        } as RequestEventCreateModel;

        if (selectedDate.value) {
          event.plannedDate = selectedDate.value;
        }
        if (onBehalfUser.value) {
          event.onBehalfUserId = onBehalfUser.value.id;
        }

        const sendPost = async (event: RequestEventCreateModel) => {
          const result = selectedDate.value
            ? await postStore.eventPlannedCreate(event)
            : await postStore.eventCreate(event);

          if (result) {
            emit('onSendSuccessful');
            postStore.$patch({
              draftPosts: {
                event: null,
              },
            });
            removeAllFiles();
          } else {
            await showToast(t('feed.postNotSend'), false);
          }
        };

        if (props.postType === PostTypeActionEnum.Event) {
          await sendPost(event);
          // Event in calendar
        } else if (props.postType === PostTypeActionEnum.CalendarEvent) {
          if (!(await eventStore.eventCreate(event))) {
            await showToast(t('feed.event.eventNotSend'), false);
          } else {
            emit('onSendSuccessful');
            postStore.$patch({
              draftPosts: {
                event: null,
              },
            });
            removeAllFiles();
          }
        }
      }
      break;

    //Announcement
    case PostTypeActionEnum.Announcement:
      {
        const announcement = {
          title: props.announcementData.title.trim(),
          text: props.announcementData.text.trim(),
          isRequiredToRead: props.announcementData.isRequiredToRead,
          imageId: data.postPhotoId,
          groupId: data.groupToPost?.id,
          fileTempIds: data.files.map((f: FileModel) => f.key),
          fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
          wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
        } as RequestAnnouncementCreateModel;

        if (selectedDate.value) {
          announcement.plannedDate = selectedDate.value;
        }
        if (onBehalfUser.value) {
          announcement.onBehalfUserId = onBehalfUser.value.id;
        }

        const sendPost = async (
          announcement: RequestAnnouncementCreateModel
        ) => {
          const result = selectedDate.value
            ? await postStore.announcementPlannedCreate(announcement)
            : await postStore.announcementCreate(announcement);

          if (result) {
            emit('onSendSuccessful');
            postStore.$patch({
              draftPosts: {
                announcement: null,
              },
            });
            removeAllFiles();
          } else {
            await showToast(t('feed.postNotSend'), false);
          }
        };
        await sendPost(announcement);
      }
      break;

    //Poll
    case PostTypeActionEnum.Poll:
      {
        const poll = {
          text: props.pollData.text.trim(),
          allowUserAddOptions: props.pollData.allowUserAddOptions,
          allowMultipleAnswers: props.pollData.allowMultipleAnswers,
          isAnonymousVoting: props.pollData.isAnonymousVoting,
          limitDate: props.pollData.limitDate,
          questionOptions: props.pollData.questionOptions,
          toGroupId: data.groupToPost?.id,
          fileTempIds: data.files.map((f: FileModel) => f.key),
        } as RequestPollCreateModel;

        if (selectedDate.value) {
          poll.plannedDate = selectedDate.value;
        }
        if (onBehalfUser.value) {
          poll.onBehalfUserId = onBehalfUser.value.id;
        }

        const sendPost = async (poll: RequestPollCreateModel) => {
          const result = selectedDate.value
            ? await postStore.pollPlannedCreate(poll)
            : await postStore.pollCreate(poll);

          if (result) {
            emit('onSendSuccessful');
            postStore.$patch({
              draftPosts: {
                poll: null,
              },
            });
            removeAllFiles();
          } else {
            await showToast(t('feed.postNotSend'), false);
          }
        };
        await sendPost(poll);
      }
      break;

    //Task
    case PostTypeActionEnum.Task:
      {
        const task = {
          title: props.taskData.title.trim(),
          tagsIds: props.taskData.tagsIds,
          dateDue: props.taskData.dateDue,
          assigneeId: props.taskData.assigneeId,
          milestoneId: props.taskData.milestoneId,
          description: props.taskData.description.trim(),
          projectId: props.taskData.projectId,
          fileTempIds: data.files.map((f: FileModel) => f.key),
          fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
          wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
        } as TaskManagementCreateTaskRequestModel;
        if (!(await projectsStore.taskCreate(task))) {
          await showToast(t('feed.postNotSend'), false);
        } else {
          emit('onSendSuccessful');
          postStore.$patch({
            draftPosts: {
              task: null,
            },
          });
          removeAllFiles();
        }
      }
      break;

    //Edit text
    case PostTypeActionEnum.EditText:
      {
        const editData = {
          text:
            props.updateData.text.length === 0
              ? ' '
              : props.updateData.text.trim(),
          fileTempIds: data.files.map((f: FileModel) => f.key),
          fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
          wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
        } as RequestPostEditModel;

        if (props.postData?.plannedPostData?.plannedDate) {
          editData.plannedDate = props.postData?.plannedPostData?.plannedDate;
        }
        if (onBehalfUser.value) {
          editData.onBehalfUserId = onBehalfUser.value.id;
        }

        const updatePost = async (editData: RequestPostEditModel) => {
          const result =
            props.postData?.plannedPostData !== null
              ? await postStore.postPlannedUpdate(props.postId, editData)
              : await postStore.postEdit(props.postId, editData);

          if (result) {
            await showToast(
              t('feed.conversationPostMenu.edit.postEdited'),
              true
            );
            emit('onSendSuccessful');
          } else {
            await showToast(
              t('feed.conversationPostMenu.edit.postNotEdited'),
              false
            );
          }
        };
        await updatePost(editData);
      }
      break;

    //Edit event
    case PostTypeActionEnum.EditEvent:
      {
        const event = {
          title: props.eventData.title.trim(),
          text: props.eventData.text.trim(),
          date: props.eventData.date,
          duration: props.eventData.duration,
          place: props.eventData.place.trim(),
          userIds: props.eventData.userIds,
          groupIds: props.eventData.groupIds,
          userEmails: props.eventData.userEmails,
          toGroupID: data.groupToPost?.id,
          endDate: props.eventData.endDate,
          isBlocker: props.eventData.isBlocker,
        } as RequestEventCreateModel;

        if (props.postData?.plannedPostData?.plannedDate) {
          event.plannedDate = props.postData?.plannedPostData?.plannedDate;
        }
        if (onBehalfUser.value) {
          event.onBehalfUserId = onBehalfUser.value.id;
        }

        const updatePost = async (event: RequestEventCreateModel) => {
          const result =
            props.postData?.plannedPostData !== null
              ? await postStore.eventPlannedUpdate(props.postId, event)
              : await postStore.eventEdit(props.postId, event);

          if (result) {
            await showToast(
              t('feed.conversationPostMenu.edit.postEdited'),
              true
            );
            emit('onSendSuccessful');
          } else {
            await showToast(
              t('feed.conversationPostMenu.edit.postNotEdited'),
              false
            );
          }
        };
        await updatePost(event);
      }
      break;

    //Edit idea
    case PostTypeActionEnum.EditIdea:
      {
        const idea = {
          title: props.ideaData.title.trim(),
          problem: props.ideaData.problem.trim(),
          solution: props.ideaData.solution.trim(),
          results: props.ideaData.results.trim(),
          toGroupID: data.groupToPost?.id,
          fileTempIds: data.files.map((f: FileModel) => f.key),
          fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
          wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
          fileIds: [],
        } as RequestIdeaCreateModel;

        if (props.postData?.plannedPostData?.plannedDate) {
          idea.plannedDate = props.postData?.plannedPostData?.plannedDate;
        }
        if (onBehalfUser.value) {
          idea.onBehalfUserId = onBehalfUser.value.id;
        }

        const updatePost = async (idea: RequestIdeaEditModel) => {
          const result =
            props.postData?.plannedPostData !== null
              ? await postStore.ideaPlannedUpdate(props.postId, idea)
              : await postStore.ideaEdit(props.postId, idea);

          if (result) {
            await showToast(
              t('feed.conversationPostMenu.edit.postEdited'),
              true
            );
            emit('onSendSuccessful');
          } else {
            await showToast(
              t('feed.conversationPostMenu.edit.postNotEdited'),
              false
            );
          }
        };

        await updatePost(idea);
      }
      break;

    //Edit poll
    case PostTypeActionEnum.EditPoll:
      {
        const poll = {
          text: props.pollData.text.trim(),
          /* //TODO: return for #1548  limitDate: props.postData.pollData?.timeLimit
            ? DateHelper.formatDateToISO(
                new Date(
                  DateHelper.getUtcZero(props.postData.pollData.timeLimit)
                )
              )
            : '', */
          limitDate: props.postData.pollData?.timeLimit
            ? DateHelper.formatDateWithTime(
                new Date(
                  DateHelper.getUtcZero(props.postData.pollData.timeLimit)
                ).toISOString()
              )
            : '',
          questionOptions: props.postData.pollData?.options.map((option) =>
            option.text.trim()
          ) || [''],
          fileTempIds: data.files.map((f: FileModel) => f.key),
        } as RequestPollEditModel;

        if (props.postData?.plannedPostData?.plannedDate) {
          poll.plannedDate = props.postData?.plannedPostData?.plannedDate;
        }
        if (onBehalfUser.value) {
          poll.onBehalfUserId = onBehalfUser.value.id;
        }

        const updatePost = async (poll: RequestPollEditModel) => {
          const result =
            props.postData?.plannedPostData !== null
              ? await postStore.pollPlannedUpdate(props.postId, poll)
              : await postStore.pollEdit(props.postId, poll);

          if (result) {
            await showToast(
              t('feed.conversationPostMenu.edit.postEdited'),
              true
            );
            emit('onSendSuccessful');
          } else {
            await showToast(
              t('feed.conversationPostMenu.edit.postNotEdited'),
              false
            );
          }
        };
        await updatePost(poll);
      }
      break;

    //Edit announcement
    case PostTypeActionEnum.EditAnnouncement:
      {
        const announcement = {
          title: props.announcementData.title.trim(),
          text: props.announcementData.text.trim(),
          isRequiredToRead: props.announcementData.isRequiredToRead,
          imageId: data.postPhotoId,
          groupId: data.groupToPost,
          fileTempIds: data.files.map((f: FileModel) => f.key),
          fileExistIds: data.attachedFiles.map((f: FileModel) => f.key),
          wikiIds: data.attachedWikis.map((f: WikiModel) => f.id),
        } as RequestAnnouncementCreateModel;

        if (props.postData?.plannedPostData?.plannedDate) {
          announcement.plannedDate =
            props.postData?.plannedPostData?.plannedDate;
        }
        if (onBehalfUser.value) {
          announcement.onBehalfUserId = onBehalfUser.value.id;
        }

        const updatePost = async (
          announcement: RequestAnnouncementEditModel
        ) => {
          const result =
            props.postData?.plannedPostData !== null
              ? await postStore.announcementPlannedUpdate(
                  props.postId,
                  announcement
                )
              : await postStore.announcementEdit(props.postId, announcement);

          if (result) {
            await showToast(
              t('feed.conversationPostMenu.edit.postEdited'),
              true
            );
            emit('onSendSuccessful');
          } else {
            await showToast(
              t('feed.conversationPostMenu.edit.postNotEdited'),
              false
            );
          }
        };
        await updatePost(announcement);
      }
      break;
  }

  //NOTE: #1682 OnlyOffice attachment content loss - fast fix
  onlyOfficeFileHere.value = false;

  isSendLoading.value = false;
};

const keyUp = (event: KeyboardEvent | MouseEvent) => {
  if (keyUpSend(event)) actionSend();
};

const isAttachmentsEqual = () => {
  const allAttachedFiles = [...attachedFiles.value, ...files.value];
  const attachedFilesEqual =
    attachedOriginFiles.value.length === allAttachedFiles.length &&
    attachedOriginFiles.value.every((attachedFile) =>
      allAttachedFiles.find(
        (newAttachedFile) => newAttachedFile.key === attachedFile.key
      )
    );
  const attachedWikisEqual =
    attachedOriginWikis.value.length === attachedWikis.value.length &&
    attachedOriginWikis.value.every((attachedWiki) =>
      attachedWikis.value.find(
        (newAttachedWiki) => newAttachedWiki.id === attachedWiki.id
      )
    );
  const tempFilesEqual =
    tempOriginFiles.value.length === tempFiles.value.length &&
    tempOriginFiles.value.every((tempFile) =>
      tempFiles.value.find((newTempFile) => newTempFile.key === tempFile.key)
    );
  return attachedFilesEqual && attachedWikisEqual && tempFilesEqual;
};

const attachAction = async (event: Event) => {
  const fromDevice = async () => {
    // Picking file
    const attachFiles = await filesHybrid.pickFiles(
      UploadFileTypes.ManyDifferentFiles
    );

    if (!attachFiles.length) {
      throw new Error('No files were picked from device');
    }
    // Saving file
    if (attachFiles.length > 0) {
      let attached = [];
      for (let i = 0; i < attachFiles.length; i++) {
        const _file = await filesHybrid.toFile(attachFiles[i]);
        if (_file !== undefined) {
          files.value.push(_file);
          attached.push({
            index: i,
            payload: _file.payload,
          });
        } else {
          await showToast(
            t('uploadFileIsLarger', {
              size: import.meta.env.VITE_APP_MAX_SIZE,
            }),
            false
          );
        }
      }

      if (!attached.length) {
        throw new Error('No files were uploaded');
      }

      // Uploading file
      attached = attached.map(async (file) => {
        const uploadFile = await filesHybrid.uploadFile(
          attachFiles[file.index]
        );
        const index = files.value.findIndex((f) => f.payload === file.payload);
        if (~index && uploadFile !== undefined) {
          files.value.splice(index, 1, uploadFile);
          return file;
        }
        return null;
      });
      if (attached.every((file) => file === null)) {
        throw new Error('No files were uploaded');
      }
    }
  };

  const fromAttachment = async () => {
    //NOTE: If the post is created - take the group from the selector. If the post is created - take the group from the post
    let groupData;
    if (selectedGroupToPost.value) {
      groupData = selectedGroupToPost.value;
    } else if (props.postData?.group) {
      groupData = props.postData.group ?? null;
    }

    const result = await componentDocsAttachment(groupData, false);

    if (result.data === undefined) {
      throw new Error('No files were attached');
    }
    const data = result.data as DocEntity[];
    data.forEach((element) => {
      if (element.documentType === DocumentTypeEnum.Wiki) {
        if (!attachedWikis.value.some((o) => o.id === element.data.id)) {
          attachedWikis.value = [
            element.data as WikiModel,
            ...attachedWikis.value,
          ];
          postStore.$patch({
            draftPosts: {
              wikis: attachedWikis.value,
            },
          });
        }
      } else {
        if (!attachedFiles.value.some((o) => o.id === element.data.id)) {
          attachedFiles.value = [
            element.data as FileModel,
            ...attachedFiles.value,
          ];
          if (
            props.postType !== PostTypeActionEnum.Comment &&
            props.postType !== PostTypeActionEnum.TaskComment
          ) {
            postStore.$patch({
              draftPosts: {
                existFiles: attachedFiles.value,
              },
            });
          }
        }
      }
    });
  };

  if (
    props.postType === PostTypeActionEnum.Poll ||
    props.postType === PostTypeActionEnum.EditPoll
  ) {
    try {
      await fromDevice();
    } catch (e) {
      console.error('fromDevice error: ', e);
      return;
    }
  } else {
    const result = isAnyMobile
      ? await postUploadFileMenuSheet(selectedGroupToPost.value?.id ?? null)
      : await postUploadFileMenu(event, selectedGroupToPost.value?.id ?? null);

    if (result.data === PostUploadFileEnum.UploadFromDevice) {
      try {
        await fromDevice();
      } catch (e) {
        console.error('fromDevice error: ', e);
        return;
      }
    } else if (result.data === PostUploadFileEnum.UploadFromGroup) {
      try {
        await fromAttachment();
      } catch (e) {
        console.error('fromAttachment error: ', e);
        return;
      }
    } else if (result.data === PostUploadFileEnum.CreateDocument) {
      const result = await componentDocsCreateFile(
        false,
        selectedGroupToPost.value?.id
      );

      if (!result) {
        console.error('Create document error');
        return;
      }

      //NOTE: #1682 OnlyOffice attachment content loss - fast fix
      onlyOfficeFileHere.value = true;

      tempFiles.value.push(result);
      if (
        props.postType !== PostTypeActionEnum.Comment &&
        props.postType !== PostTypeActionEnum.TaskComment
      ) {
        postStore.$patch({
          draftPosts: {
            tempFiles: attachedFiles.value,
          },
        });
      }
    } else if (result.data === PostUploadFileEnum.CreateWiki) {
      const result = await componentWikiCreate(
        selectedGroupToPost.value?.id,
        undefined, //TODO: WE should pass the group id and folder id
        true
      );

      if (!result) {
        console.error('Create wiki error');
        return;
      }

      const newWiki = await useWikiStore().getWikiById(result);
      if (!newWiki) {
        console.error('Create wiki error');
        return;
      }

      attachedWikis.value.push(newWiki);
      if (
        props.postType !== PostTypeActionEnum.Comment &&
        props.postType !== PostTypeActionEnum.TaskComment
      ) {
        postStore.$patch({
          draftPosts: {
            wikis: attachedWikis.value,
          },
        });
      }
    }
  }

  if (checkAttachments.value) {
    isAttachmentChanged.value = !isAttachmentsEqual();
  }
};

const selectChange = (groupData: GroupEntity | undefined) => {
  selectedGroupToPost.value = groupData !== undefined ? groupData : undefined;

  /* NOTE: Remove attachments from other sources
  Do not remove files downloaded from the device, as they do not have their own location */

  const filterByGroupId = (item: any) => item.group?.id === groupData?.id;
  const filterByNullGroup = (item: any) => item.group === null;

  /* files.value = []; */
  tempFiles.value = groupData?.id
    ? filter(tempFiles.value, filterByGroupId)
    : filter(tempFiles.value, filterByNullGroup);
  attachedFiles.value = groupData?.id
    ? filter(attachedFiles.value, filterByGroupId)
    : filter(attachedFiles.value, filterByNullGroup);
  attachedWikis.value = groupData?.id
    ? filter(attachedWikis.value, filterByGroupId)
    : filter(attachedWikis.value, filterByNullGroup);
};

const commentFocus = async () => {
  textAreaEl.value = await textAreaRef.value?.$el.getInputElement();
  emit('onCommentFocus', true);
  postStore.$patch({
    activePost: props.postId,
  });
};

const selectDate = (date: string) => {
  selectedDate.value = date;
};

const selectOnBehalfUser = (user: UserShortModel) => {
  onBehalfUser.value = user;
};

const commentBlur = () => {
  emit('onCommentFocus', false);

  resetActivePost();
};

const resetActivePost = debounce(async () => {
  userStore.resetUsersIdsChosen(UsersFilterEnum.ChosenMentions);
}, 150);

const setFieldText = async (comment: CommentsDataModel | null) => {
  if (props.postType === PostTypeActionEnum.Comment && comment) {
    text.value = '@' + comment.author.mainAlias + ', ';
  }
};

const setFieldFocus = async () => {
  await textAreaRef.value?.$el.setFocus();
};

const onDeleteFile = async (file: FileModel | any) => {
  files.value = files.value.filter((f: FileModel) => f.key !== file.key);
  tempFiles.value = tempFiles.value.filter(
    (f: FileModel) => f.key !== file.key
  );
  attachedFiles.value = attachedFiles.value.filter(
    (f: FileModel) => f.id !== file.id
  );
  attachedWikis.value = attachedWikis.value.filter(
    (f: any) => f.id !== file.id
  );
  postStore.$patch({
    draftPosts: {
      tempFiles: files.value,
      existFiles: attachedFiles.value,
      wikis: attachedWikis.value,
    },
  });
  await filesHybrid.remove(file.key);
  if (checkAttachments.value) {
    isAttachmentChanged.value = !isAttachmentsEqual();
  }
};

const onDeleteWiki = async (file: WikiModel) => {
  attachedWikis.value = attachedWikis.value.filter(
    (f: WikiModel) => f.id !== file.id
  );
  postStore.$patch({
    draftPosts: {
      wikis: attachedWikis.value,
    },
  });
  if (checkAttachments.value) {
    isAttachmentChanged.value = !isAttachmentsEqual();
  }
};

const removeAllFiles = () => {
  files.value = [];
  tempFiles.value = [];
  attachedFiles.value = [];
  attachedWikis.value = [];

  //NOTE: #1682 OnlyOffice attachment content loss - fast fix
  onlyOfficeFileHere.value = false;

  postStore.$patch({
    draftPosts: {
      tempFiles: files.value,
      existFiles: attachedFiles.value,
      wikis: attachedWikis.value,
    },
  });
};

const onChange = async () => {
  if (textAreaEl.value !== null) {
    caret.value = textAreaEl.value.selectionStart;
  }
};

const onAppend = async (data: { alias: string; index: number }) => {
  const message = text.value.substring(data.index);
  const indexSpace = message.indexOf(' ');

  const startMessage =
    text.value.substring(0, data.index + 1) + data.alias + ' ';

  if (~indexSpace) {
    text.value = startMessage + message.substring(indexSpace);
    await textAreaRef.value?.$el.setFocus();
    postStore.$patch({
      activePost: props.postId,
    });
    const nativeEl = await textAreaRef.value?.$el.getInputElement();
    nativeEl.setSelectionRange(startMessage.length, startMessage.length);
  } else {
    text.value = startMessage;
    await textAreaRef.value?.$el.setFocus();
  }
  caret.value = text.value.length;
};

defineExpose({
  setFieldText,
  setFieldFocus,
});

//NOTE: Publisher Drop Zone
const dropLoading = ref<boolean>(false);
const { on: onPublisherDropZone } = useEventBus<boolean>(
  EventBusEnum.PublisherDropZone
);
onPublisherDropZone((isActive: boolean) => {
  dragIsActive.value = isActive;
});
const dropedFiles = (filesArray: FileModel[]) => {
  files.value.push(
    ...filesArray.filter(
      (newFile) => !files.value.some((file) => file.key === newFile.key)
    )
  );
};
const dropIsLoading = (loading: boolean) => {
  dropLoading.value = !loading;
};

// Watchers
const keyUpAction: ComputedRef<boolean> = computed(() => props.keyUpAction);
watch(keyUpAction, () => {
  if (keyUpAction.value) actionSend();
});

watch(uploads, () => {
  if (
    props.postType !== PostTypeActionEnum.Comment &&
    props.postType !== PostTypeActionEnum.TaskComment
  ) {
    postStore.$patch({
      draftPosts: {
        tempFiles: uploads.value,
      },
    });
  }
});

watch(onBehalfUser, (newValue, oldValue) => {
  isOnBehalfUserChanged.value = newValue !== oldValue;
});

// Lifecycle
onMounted(() => {
  switch (props.postType) {
    case PostTypeActionEnum.Text:
    case PostTypeActionEnum.Event:
    case PostTypeActionEnum.CalendarEvent:
    case PostTypeActionEnum.Announcement:
    case PostTypeActionEnum.Poll:
    case PostTypeActionEnum.Idea:
      {
        tempFiles.value = draftTempFiles.value;
        attachedFiles.value = draftExistFiles.value;
        attachedWikis.value = draftWikis.value;
      }

      break;
    case PostTypeActionEnum.EditText:
    case PostTypeActionEnum.EditEvent:
    case PostTypeActionEnum.EditIdea:
    case PostTypeActionEnum.EditPoll:
    case PostTypeActionEnum.EditAnnouncement: {
      attachedOriginFiles.value = props.postData.attachedFiles.data;
      attachedOriginWikis.value = props.postData.attachedWikis.data;
      attachedFiles.value = props.postData.attachedFiles.data;
      attachedWikis.value = props.postData.attachedWikis.data;
      if (props.postData?.plannedPostData !== null) {
        tempOriginFiles.value = props.postData.plannedPostData.tempFiles;
        tempFiles.value = props.postData.plannedPostData.tempFiles;
      }
    }
  }
});

onUnmounted(() => {
  files.value = [];
});

// Emits
const emit = defineEmits(['onSendSuccessful', 'onCommentFocus']);
</script>
<style scoped lang="scss">
ion-toolbar {
  --padding-bottom: 4px;
  --padding-top: 4px;
  --padding-end: 4px;
  --padding-start: 4px;
  height: auto;
  border-radius: app-radius(md);
  --background: var(--ion-color-light-background);
}
ion-toolbar .toolbar-container {
  display: flex;
  align-items: end;
}
ion-toolbar.task-toolbar {
  --background: var(--ion-color-light);
  --min-height: unset;
  --padding-top: 4px;
  --padding-bottom: 4px;
}
ion-toolbar.task-toolbar .attach-btn {
  --background: var(--ion-color-light);
  --padding-start: unset;
}
ion-toolbar.task-toolbar .send-btn {
  --min-height: unset;
}
ion-toolbar.task-toolbar ion-textarea {
  --padding-bottom: unset;
  margin-bottom: 6px;
}

ion-toolbar .toolbar-container ion-item {
  --background: var(--ion-color-light-background);
}

ion-toolbar .toolbar-container ion-item ion-icon {
  @include respond-to-max-width(sm) {
    width: 3rem;
  }
}

ion-textarea {
  margin-top: 0;
  max-height: 14rem;
  margin-bottom: 4px;
  --padding-start: 4px;
  align-self: auto;
  overflow: auto;
  --padding-top: #{app-padding(lg)};
}
.files-list {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
}
.file-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}
.file-wrapper file-info {
  justify-content: start;
  align-items: start;
}
.file-wrapper img {
  max-height: 80px;
  max-width: 80px;
}
.files-list ion-icon {
  color: var(--ion-color-intra);
}
.loader {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.post_create-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  .drag {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
  }
}
.post_create-controls .send {
  display: flex;
  justify-content: end;
  flex-grow: 1;
  overflow: hidden;

  @include respond-to-max-width(2xs) {
    justify-content: unset;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 8px;
  }
}
.post_create-controls .attach {
  margin-right: app-padding(md);
}
.behalf-select-button {
  height: 100%;
}

.group-select-button {
  flex-shrink: 1;
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
}

.schedule-post-button {
  flex-shrink: 1;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 0.5rem;
}
.schedule-post-button:has(.delete-button) {
  margin-left: 0;
}

.preview-post-button {
  margin-left: 0.5rem;
  min-width: 116px;
}
.preview-post-button ion-button {
  min-height: 40px;
  --background: transparent !important;
  --border-color: var(--ion-color-custom-element-lighter);
  width: 100%;
  min-width: 0;
  --background-hover: var(--ion-color-custom-element-lighter);
}
.wrap-text {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  white-space: nowrap !important;
}

.post_create-controls .submit {
  margin-left: 0.5rem;
}
.post_create-controls ion-button {
  --background: var(--ion-color-light-background-contrast-custom);
  --color: var(--ion-color-custom-gray-darker);
  --box-shadow: none;
  margin: 0;
  --border-radius: #{app-radius(md)};
  min-height: calc(app-padding(md) * 5);
}
.post_create-controls ion-button.delete {
  --color: var(--ion-color-custom);
  --background: var(--ion-color-danger);
  --border-radius: #{app-radius(md)};
}
</style>
