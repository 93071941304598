<template>
  <div :class="['app-grid', { 'grid-fixed': fixed }]">
    <slot />
  </div>
</template>

<script lang="ts" setup>
defineProps({
  fixed: {
    type: Boolean,
    default: () => false,
  },
});
</script>

<style lang="scss" scoped>
.app-grid {
  -webkit-margin-start: auto;
  margin-inline-start: auto;
  -webkit-margin-end: auto;
  margin-inline-end: auto;
  display: block;
  -ms-flex: 1;
  flex: 1;

  &.grid-fixed {
    width: var(--ion-grid-width-xs, var(--ion-grid-width, 100%));
    max-width: 100%;
  }
}
@include respond-to-min-width(sm) {
  .app-grid {
    &.grid-fixed {
      width: var(
        --ion-grid-width-sm,
        var(--ion-grid-width, var(--app-sm-size))
      );
    }
  }
}
@include respond-to-min-width(md) {
  .app-grid {
    &.grid-fixed {
      width: var(
        --ion-grid-width-md,
        var(--ion-grid-width, var(--app-md-size))
      );
    }
  }
}
@include respond-to-min-width(lg) {
  .app-grid {
    &.grid-fixed {
      width: var(
        --ion-grid-width-lg,
        var(--ion-grid-width, var(--app-lg-size))
      );
    }
  }
}
@include respond-to-min-width(xl) {
  .app-grid {
    &.grid-fixed {
      width: var(
        --ion-grid-width-xl,
        var(--ion-grid-width, var(--app-xl-size))
      );
    }
  }
}
@include respond-to-min-width(2xl) {
  .app-grid {
    &.grid-fixed {
      width: var(
        --ion-grid-width-2xl,
        var(--ion-grid-width, var(--app-2xl-size))
      );
    }
  }
}

@include respond-to-min-width(3xl) {
  .app-grid {
    &.grid-fixed {
      width: var(
        --ion-grid-width-3xl,
        var(--ion-grid-width, var(--app-3xl-size))
      );
    }
  }
}

@include respond-to-min-width(4xl) {
  .app-grid {
    &.grid-fixed {
      width: var(
        --ion-grid-width-4xl,
        var(--ion-grid-width, var(--app-4xl-size))
      );
    }
  }
}

.ion-no-padding {
  --ion-grid-column-padding: 0;
  --ion-grid-column-padding-xs: 0;
  --ion-grid-column-padding-sm: 0;
  --ion-grid-column-padding-md: 0;
  --ion-grid-column-padding-lg: 0;
  --ion-grid-column-padding-xl: 0;
  --ion-grid-column-padding-2xl: 0;
  --ion-grid-column-padding-3xl: 0;
}
</style>
