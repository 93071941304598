<template>
  <div class="box">
    <icons-provider
      :icon-props="{ width: '64', height: '64' }"
      name="circleAnim"
      fill="var(--ion-color-medium)"
    />
  </div>
</template>

<script lang="ts" setup>
import { IconsProvider } from '@/components';
</script>

<style scoped lang="scss">
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
