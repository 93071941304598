<template>
  <ion-footer ref="bottomMenuRef">
    <div class="app-footer-menu">
      <div
        v-for="(item, index) in activeMenuItems"
        v-show="item.enabled"
        :key="`menu-item_${index}`"
        class="item"
        :class="{
          active: item.isActive,
          'without-label': !menuWithLabels,
          mobile: isAnyMobile,
        }"
        @click="menuItemClick($event, item)"
      >
        <icons-provider
          class="icon"
          :icon-props="{
            width: '24',
            height: '24',
            fill: 'var(--ion-color-custom)',
          }"
          :name="item.icon"
        />
        <ion-label v-if="menuWithLabels" class="fw-400">
          {{ item.title }}
        </ion-label>
        <ion-badge v-if="showBadge(item)" slot="end" mode="md" class="counter">
          {{ badgeCount(item) > 99 ? `${'99+'}` : badgeCount(item) }}
        </ion-badge>
      </div>
    </div>
  </ion-footer>
</template>

<script lang="ts" setup>
import { IonLabel, IonFooter, IonBadge } from '@ionic/vue';
import type { Route } from '@sentry/vue/types/router';
import { useElementSize, useWindowFocus } from '@vueuse/core';
import type { ComponentPublicInstance, ComputedRef } from 'vue';
import { ref, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { IconsProvider } from '@/components';
import { AppFabButtonEnum, AppMenuEnum } from '@/enums';
import {
  useMenu,
  componentAppSubMenuPopover,
  componentLoginModal,
  useFabBtn,
  useAdminHelper,
  isAnyMobile,
} from '@/helpers';
import { useAppStore, useMessengerStore, useNotificationsStore } from '@/store';
import type { AppMenuItem } from '@/types';

// Store
const appStore = useAppStore();
const notificationsStore = useNotificationsStore();
const messengerStore = useMessengerStore();

// Router
const router = useRouter();
const route = useRoute();

// Refs
const bottomMenuRef = ref<ComponentPublicInstance | null>(null);
const menuWithLabels = ref<boolean>(true);

// Helpers
const { height } = useElementSize(bottomMenuRef);
const focused = useWindowFocus();
const menuHelper = useMenu();
const adminHelper = useAdminHelper();

// Computed
const notificationsCount: ComputedRef<number> = computed(
  () => notificationsStore.totalUnreadCount
);
const messagesCount: ComputedRef<number> = computed(
  () => messengerStore.getUnreadMessagesCount
);
const showBadge = (item: AppMenuItem): boolean => {
  return (
    (item.name === AppMenuEnum.Notifications && notificationsCount.value > 0) ||
    (item.name === AppMenuEnum.Messenger && messagesCount.value > 0)
  );
};
const badgeCount = (item: AppMenuItem): number => {
  return item.name === AppMenuEnum.Notifications
    ? notificationsCount.value
    : item.name === AppMenuEnum.Messenger
      ? messagesCount.value
      : 0;
};

const activeMenuItems = computed(() => {
  return menuHelper.getFooterMenu().map((item) => ({
    ...item,
    isActive: isActive(item, route),
  }));
});

// Methods
const isActive = (element: AppMenuItem, route: Route): boolean => {
  const caseOne = !route.params?.id && element.link?.name === route.name;
  const caseTwo =
    !!route.params?.id &&
    !!element.link?.params?.id &&
    +route.params.id === element.link?.params.id &&
    element.link?.name === route.name;

  return caseOne || caseTwo;
};

const menuItemClick = async (ev: Event, item: AppMenuItem) => {
  if (item.submenu?.length) {
    await componentAppSubMenuPopover(ev, item.submenu, 'top');
    return;
  }

  if (item.href && (await menuHelper.validateCustomLink(item.href))) {
    window.open(new URL(item.href), '_blank');
    return;
  }

  if (item.link) {
    await router.push(item.link);
    return;
  }

  if (item.name === AppMenuEnum.Login) {
    await componentLoginModal();
    return;
  }

  if (item.name === AppMenuEnum.Plus) {
    //NOTE: Temporary solution for hardcoding the CreatePost fab button
    await useFabBtn(focused.value, false, adminHelper).handleFabClick(
      ev,
      AppFabButtonEnum.CreatePost
    );
    return;
  }
};

// Watchers
watch(height, () => {
  appStore.$patch({ appBottomMenuHeight: height.value });
});

watch(focused, () => {
  appStore.$patch({ appBottomMenuHeight: height.value });
});
</script>

<style scoped lang="scss">
ion-footer {
  z-index: 1000;
  background: var(--ion-color-intra);
  box-shadow: none;
}

.app-footer-menu {
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--ion-safe-area-bottom);
  box-sizing: border-box;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    opacity: 0.8;
    position: relative;
    color: var(--ion-color-custom);
    padding-top: app-padding(md);
    padding-bottom: app-padding(sm);
    position: relative;
    transition: all 0.15s ease-in-out;

    &.active {
      opacity: 1;

      &::before {
        display: block;
        content: '';
        width: 100%;
        height: 3px;
        background: var(--ion-color-custom);
        position: absolute;
        top: 0;
        border-radius: 1.5px;
      }
    }

    &.without-label {
      justify-content: center;
    }

    .icon {
      position: relative;
      margin-bottom: app-padding(sm);
    }

    .counter {
      top: 0.25rem;
      right: 50%;
      transform: translateX(100%);
      position: absolute;
      color: var(--ion-color-custom);
      z-index: 1;
      font-size: 0.7rem;
      padding: 2px 5px;
      border-radius: 1rem;
      min-width: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      --background: var(--ion-color-notification-badge);
    }

    ion-label {
      font-size: 0.7rem;
    }

    &:not(.mobile):hover {
      cursor: pointer;
      background: rgba(var(--ion-color-light-rgb), 0.1);
    }
  }
}
</style>
