import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import {
  AppFabButtonEnum,
  AppIconsEnum,
  GroupPageTabEnum,
  GroupsAccessEnum,
  PageToPostCreateEnum,
  PostTypeActionEnum,
  UserRoleEnum,
} from '@/enums';
import {
  canCreateIdeas,
  componentAdminRightMenuModal,
  componentCustomPageCreateModal,
  componentCustomPageInfoModal,
  componentPostCreateMobile,
  createPostAccessHelper,
  docBrowserOpenMenu,
  openPostCreateMenu,
  useCustomPages,
  usePostCreateMenu,
  useTaskManagement,
} from '@/helpers';
import { useI18n } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import {
  useAppStore,
  useCustomPageStore,
  useGroupsStore,
  useNetworkStore,
  usePostStore,
  useProjectsStore,
  useUserStore,
} from '@/store';
import type { GroupEntity, PageEntity, ProjectEntity } from '@/types';

export type FabData = {
  icon: string;
  value: AppFabButtonEnum | null;
  enabled: boolean;
  title?: string;
};

export type IUseFabBtnHelper = {
  /**
   * @description Computed array of objects with data for the FAB buttons
   * @todo Rewrite description and add more details
   */
  getFabData: ComputedRef<FabData[]>;
  /**
   * @description Handles the click event on the FAB button
   * @todo Rewrite description and add more details
   */
  handleFabClick(ev: Event, fab: AppFabButtonEnum): Promise<void>;
};

export const useFabBtn = (
  isFocus: boolean,
  isScrolled: boolean,
  adminHelper: any
): IUseFabBtnHelper => {
  //* Router
  const route = router.currentRoute.value;
  const { id } = route.params;
  const { t } = useI18n();

  //* Store
  const appStore = useAppStore();
  const postStore = usePostStore();
  const pageStore = useCustomPageStore();
  const groupStore = useGroupsStore();
  const userStore = useUserStore();
  const networkStore = useNetworkStore();
  const projectsStore = useProjectsStore();

  //* Helpers
  const customPagesHelper = useCustomPages();
  const taskManagementHelper = useTaskManagement();
  const postCreateMenu = usePostCreateMenu();

  const isMDWidth: ComputedRef<boolean> = computed(() => appStore.isMDWidth);
  const isLGWidth: ComputedRef<boolean> = computed(() => appStore.isLGWidth);
  const currentUserRoleId: ComputedRef<number> = computed(
    () => userStore.current?.roleId ?? 0
  );
  const postsIsLoading: ComputedRef<boolean> = computed(() => {
    return postStore.loading;
  });
  const announcementsIsLoading: ComputedRef<boolean> = computed(() => {
    return postStore.loading;
  });
  const feedIsLoading: ComputedRef<boolean> = computed(() => {
    return postsIsLoading.value || announcementsIsLoading.value;
  });
  const groupData: ComputedRef<GroupEntity> = computed(() =>
    groupStore.getGroupById(Number(id))
  );
  const showPostCreateFabInIdeasPage: ComputedRef<boolean> = computed(
    () => isScrolled && canCreateIdeas()
  );
  const currentProject: ComputedRef<ProjectEntity> = computed(
    () => projectsStore.getCurrentProject
  );
  const editablePage: ComputedRef<PageEntity | null> = computed(
    () => pageStore.editablePage
  );
  const accessToDashboardEdit: ComputedRef<boolean> = computed(() => {
    return customPagesHelper.getAccessToPageEdit(editablePage.value, true);
  });
  const accessToPageEdit: ComputedRef<boolean> = computed(() => {
    return customPagesHelper.getAccessToPageEdit(editablePage.value, false);
  });
  const pageById: ComputedRef<PageEntity | null> = computed(
    () => pageStore.getPageById(Number(id)) ?? null
  );
  const accessToPageEdit2: ComputedRef<boolean> = computed(() => {
    return customPagesHelper.getAccessToPageEdit(pageById.value, false);
  });
  const accessToDashboardEdit2: ComputedRef<boolean> = computed(() => {
    return customPagesHelper.getAccessToPageEdit(pageByGroup.value, true);
  });
  const groupSelectedTab: ComputedRef<GroupPageTabEnum> = computed(
    () => groupStore.selectedTab
  );
  const networkAllowPostToFeed: ComputedRef<boolean> = computed(
    () => networkStore.settings?.allowPostToFeed ?? false
  );

  const showPostCreateFab: ComputedRef<boolean> = computed(() => {
    return (
      /* isScrolled && */ (!isMDWidth.value &&
        createPostAccessHelper(PageToPostCreateEnum.Feed, true, false)) ||
      /* isScrolled && */ (!isMDWidth.value &&
        !isFocus &&
        createPostAccessHelper(
          PageToPostCreateEnum.Feed,
          true,
          feedIsLoading.value
        ))
    );
  });

  const showPostCreateFabInTopicPage: ComputedRef<boolean> = computed(() => {
    return createPostAccessHelper(
      PageToPostCreateEnum.Tag,
      true,
      postsIsLoading.value
    );
  });

  const isGroupAdmin: ComputedRef<boolean> = computed(
    () =>
      groupData.value.accessType === GroupsAccessEnum.Admin ||
      currentUserRoleId.value >= UserRoleEnum.SuperAdministrator
  );

  const showPostCreateFabInGroupPage: ComputedRef<boolean> = computed(() => {
    return (
      !isFocus &&
      createPostAccessHelper(
        PageToPostCreateEnum.Group,
        true,
        false,
        isGroupAdmin.value,
        groupData.value
      )
    );
  });

  const showPostCreateFabInGroupDashboard: ComputedRef<boolean> = computed(
    () => {
      return (
        !isFocus &&
        createPostAccessHelper(
          PageToPostCreateEnum.GroupDashboard,
          true,
          false,
          isGroupAdmin.value,
          groupData.value,
          pageByGroup.value ?? undefined
        )
      );
    }
  );

  const showPostCreateFabInCustomPage: ComputedRef<boolean> = computed(() => {
    return (
      !isFocus &&
      createPostAccessHelper(
        PageToPostCreateEnum.CustomPage,
        true,
        false,
        isGroupAdmin.value,
        undefined,
        pageById.value ?? undefined
      )
    );
  });

  const pageByGroup: ComputedRef<PageEntity | null> = computed(() => {
    const pageId = pageStore.getPageIdByGroupId(Number(id));
    if (pageId) {
      return pageStore.getPageById(pageId) ?? null;
    } else {
      return null;
    }
  });

  const adminPages: ComputedRef<string[]> = computed(() => {
    return adminHelper.getAdminPages();
  });

  const getFabData: ComputedRef<FabData[]> = computed(() => {
    const getItems = (): FabData[] => {
      switch (route.name) {
        case ROUTES_NAME.FEED:
          return [
            {
              icon: AppIconsEnum.Plus,
              value: AppFabButtonEnum.CreatePost,
              enabled: showPostCreateFab.value,
              title: t('feed.createPost'),
            },
          ];

        case ROUTES_NAME.TOPIC_BY_ID:
          return [
            {
              icon: AppIconsEnum.Plus,
              value: AppFabButtonEnum.CreatePost,
              enabled: showPostCreateFabInTopicPage.value,
              title: t('feed.createPost'),
            },
          ];

        /* case ROUTES_NAME.PROJECT_BY_ID:
      case ROUTES_NAME.TASKS:
      return {
        icon: AppIconsEnum.Plus,
        value: AppFabButtonEnum.CreateTask,
        enabled: taskManagementHelper.getAccessToCreateTask(),
      }; */

        /* case ROUTES_NAME.PROJECTS:
      return {
        icon: AppIconsEnum.Plus,
        value: AppFabButtonEnum.CreateProject,
        enabled: true,
      }; */

        /* case ROUTES_NAME.MILESTONES:
      return {
        icon: AppIconsEnum.Plus,
        value: AppFabButtonEnum.CreateMilestone,
        enabled: true,
      }; */

        case ROUTES_NAME.PAGES:
          return [
            {
              icon: AppIconsEnum.Plus,
              value: AppFabButtonEnum.CreatePage,
              enabled: true,
              title: t('create'),
            },
          ];

        case ROUTES_NAME.GROUP_DASHBOARD:
          return [
            {
              icon: AppIconsEnum.Information,
              value: AppFabButtonEnum.DashboardInfo,
              enabled: accessToDashboardEdit.value && !appStore.isMDWidth,
              title: t('customPage.info'),
            },
          ];

        case ROUTES_NAME.PAGE_EDIT:
          return [
            {
              icon: AppIconsEnum.Information,
              value: AppFabButtonEnum.PageInfo,
              enabled: accessToPageEdit.value && !appStore.isMDWidth,
              title: t('customPage.info'),
            },
          ];

        case ROUTES_NAME.PAGE_BY_ID:
          return [
            {
              icon: AppIconsEnum.Pencil,
              value: AppFabButtonEnum.EditPage,
              enabled: accessToPageEdit2.value,
              title: t('customPage.options.edit'),
            },
            {
              icon: AppIconsEnum.PencilSquare,
              value: AppFabButtonEnum.CreatePost,
              enabled: showPostCreateFabInCustomPage.value,
              title: t('feed.createPost'),
            },
          ];

        case ROUTES_NAME.CALENDAR:
          return [
            {
              icon: AppIconsEnum.Plus,
              value: AppFabButtonEnum.CreateEvent,
              enabled: true,
              title: t('create'),
            },
          ];

        case ROUTES_NAME.GROUP_BY_ID: {
          switch (groupSelectedTab.value) {
            case GroupPageTabEnum.Dashboard:
              return [
                {
                  icon: AppIconsEnum.Pencil,
                  value: AppFabButtonEnum.EditDashboard,
                  enabled: accessToDashboardEdit2.value,
                  title: t('customPage.options.editDashboard'),
                },
                {
                  icon: AppIconsEnum.PencilSquare,
                  value: AppFabButtonEnum.CreatePost,
                  enabled:
                    showPostCreateFabInGroupPage.value &&
                    showPostCreateFabInGroupDashboard.value,
                  title: t('feed.createPost'),
                },
              ];

            case GroupPageTabEnum.Feed:
              return [
                {
                  icon: AppIconsEnum.Plus,
                  value: AppFabButtonEnum.CreatePost,
                  enabled: showPostCreateFabInGroupPage.value,
                  title: t('feed.createPost'),
                },
              ];

            case GroupPageTabEnum.Files:
              return [
                {
                  icon: AppIconsEnum.Plus,
                  value: AppFabButtonEnum.UploadDocument,
                  enabled:
                    groupData.value.accessType >= GroupsAccessEnum.Member,
                  title: t('files.addDocument'),
                },
              ];
            default:
              break;
          }
        }

        case ROUTES_NAME.DOCS: {
          return [
            {
              icon: AppIconsEnum.Plus,
              value: AppFabButtonEnum.UploadDocument,
              enabled:
                networkAllowPostToFeed.value && route.path.includes('/docs'),
              title: t('files.addDocument'),
            },
          ];
        }

        case ROUTES_NAME.IDEAS: {
          return [
            {
              icon: AppIconsEnum.Plus,
              value: AppFabButtonEnum.CreatePost,
              enabled: showPostCreateFabInIdeasPage.value,
              title: t('create'),
            },
          ];
        }

        default: {
          const routeName = route.name as string;
          if (adminPages.value.includes(routeName)) {
            return [
              {
                icon: AppIconsEnum.Hamburger,
                value: AppFabButtonEnum.OpenAdminMenu,
                enabled: !isLGWidth.value,
              },
            ];
          }
          return [{ icon: AppIconsEnum.None, value: null, enabled: false }];
        }
      }
    };

    return getItems().filter((item) => item.enabled);
  });

  const handleFabClick = async (
    ev: Event,
    fab: AppFabButtonEnum
  ): Promise<void> => {
    switch (fab) {
      case AppFabButtonEnum.CreatePost:
        await openPostCreateMenu(postCreateMenu.categories.value);
        break;

      case AppFabButtonEnum.CreateTask:
        await componentPostCreateMobile(PostTypeActionEnum.Task);
        break;

      case AppFabButtonEnum.CreateProject:
        await taskManagementHelper.taskManagementProjectSwitchHelper(
          ev,
          false,
          false,
          true
        );
        break;

      case AppFabButtonEnum.CreateMilestone:
        await taskManagementHelper.taskManagementMilestonesHelper(
          ev,
          null,
          null,
          currentProject.value.id,
          true
        );
        break;

      case AppFabButtonEnum.CreatePage:
        await componentCustomPageCreateModal();
        break;

      case AppFabButtonEnum.DashboardInfo: {
        if (pageStore.editablePage) {
          await componentCustomPageInfoModal(
            pageStore.editablePage?.pageId,
            true,
            Number(id)
          );
        }
        break;
      }

      case AppFabButtonEnum.EditDashboard:
        await router.push({
          name: ROUTES_NAME.GROUP_DASHBOARD,
          params: { id: groupData.value.id },
        });
        break;

      case AppFabButtonEnum.EditPage:
        await router.push({ name: ROUTES_NAME.PAGE_EDIT });
        break;

      case AppFabButtonEnum.PageInfo: {
        if (pageStore.editablePage) {
          await componentCustomPageInfoModal(
            pageStore.editablePage?.pageId,
            false
          );
        }
        break;
      }

      case AppFabButtonEnum.CreateEvent:
        await componentPostCreateMobile(PostTypeActionEnum.CalendarEvent);
        break;

      case AppFabButtonEnum.UploadDocument:
        await docBrowserOpenMenu(router, route, groupData.value.id ?? null);
        break;

      case AppFabButtonEnum.OpenAdminMenu:
        await componentAdminRightMenuModal();
        break;

      default:
        break;
    }
  };

  return {
    getFabData,
    handleFabClick,
  };
};
