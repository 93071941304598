<template>
  <div v-if="someOneBadgeIsLoading" class="loader">
    <ion-skeleton-text
      animated
      :style="{ width: `calc(1rem * ${badges.length})` }"
    />
  </div>

  <div v-show="!someOneBadgeIsLoading" class="badges-wrapper">
    <badge-item
      v-for="badge in badges"
      :key="badge"
      :badge-id="badge"
      :type="type"
      @onLoad="onLoad(badge)"
      @onError="onError(badge)"
    />
  </div>
</template>

<script lang="ts" setup>
import { IonSkeletonText } from '@ionic/vue';
import { remove } from 'lodash';
import { computed, onMounted, reactive, type PropType } from 'vue';

import { BadgeItem } from '@/components';
import type { BadgeDisplayType } from '@/enums';
import { FileStatusEnum } from '@/enums';

const props = defineProps({
  badges: {
    type: Array as PropType<number[]>,
    required: true,
  },
  type: {
    type: Number as PropType<BadgeDisplayType>,
    required: true,
  },
});

const loadStatus = reactive<{ id: number; status: FileStatusEnum }[]>([]);

const someOneBadgeIsLoading = computed(() => {
  return loadStatus.some((badge) => badge.status === FileStatusEnum.Loading);
});
const onLoad = (badgeId: number) => {
  remove(loadStatus, (status) => status.id === badgeId);
};

const onError = (badgeId: number) => {
  remove(loadStatus, (status) => status.id === badgeId);
};

onMounted(async () => {
  for (const badge of props.badges) {
    loadStatus.push({ id: badge, status: FileStatusEnum.Loading });
  }
});
</script>

<style scoped lang="scss">
.loader {
  position: relative;
  height: 100%;

  ion-skeleton-text {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    line-height: unset;
    height: 100% !important;
    border-radius: app-radius(md);
  }
}

.badges-wrapper {
  display: flex;
}
</style>
