<template>
  <div :title="eventPeriod + '\n' + event.title" class="event-body">
    <div class="vuecal__event-title" v-html="event.title" />
    <span class="vuecal__event-time">
      <span class="date" v-html="startDate" />
      <span class="separator" v-html="' - '" />
      <span class="date" v-html="endDate" />
      <span v-if="daysBetweenCount > 0" class="days">
        {{ ' +' + daysBetweenCount }}
      </span>
    </span>
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { DateHelper, formatDateHelper } from '@/helpers';
import type { CalendarEventModel } from '@/types';

const props = defineProps({
  event: {
    type: Object as PropType<CalendarEventModel>,
    required: true,
  },
});

const startDate: ComputedRef<string> = computed(() =>
  formatDateHelper(props.event.start, 'day')
);
const endDate: ComputedRef<string> = computed(() =>
  formatDateHelper(props.event.end, 'day')
);
const eventPeriod: ComputedRef<string> = computed(
  () => startDate.value + ' - ' + endDate.value
);

const daysBetweenCount: ComputedRef<number> = computed(() => {
  return DateHelper.getDaysBetweenDates(
    new Date(props.event.start),
    new Date(props.event.end)
  );
});
</script>
<style scoped lang="scss">
.event-body {
  padding: 4px;

  .vuecal__event {
    &-title {
      font-size: 0.9rem;
      white-space: wrap;
    }
    &-time {
      font-size: 0.7rem;

      .date {
        white-space: nowrap;
        overflow: hidden;
      }
      .days {
        font-size: 0.6rem;
        white-space: nowrap;
      }
    }
  }
}

.vuecal__cell-date {
  font-size: 1rem;
}
@include respond-to-min-width(2xl) {
  .vuecal__event {
    &-title {
      font-size: 1rem;
    }
    &-time {
      font-size: 0.8rem;

      .days {
        font-size: 0.7rem;
      }
    }
  }

  .vuecal__cell-date {
    font-size: 1.125rem;
  }
}

@include respond-to-max-width(md) {
  .vuecal__event {
    &-title {
      white-space: nowrap !important;
    }
  }
}
</style>
